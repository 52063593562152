<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="PACKAGES"
          :breadcrumb="[{ label: ' packages ' }, { label: 'packages List' }]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row" style="margin-top: -45px">
          <div
            class="col-md-4 col-sm- col-xs-12 col-xl-4"
            style="padding: 5px 5px 5px 5px"
            v-for="(pkg, index) in packages"
            :key="index"
          >
            <div class="card custom-card" style="height: 100%">
              <div class="row">
                <div class="col" style="padding: 10px 10px 0px 10px">
                  <img
                    class="border-row"
                    src="https://apk.mudhrape.com/wp-content/uploads/2022/01/fab2.png"
                    style="width: 17px; margin-top: -5px"
                    alt=""
                  />&nbsp;
                  <b class="text" style="font-size: 12pt"
                    >&nbsp;&nbsp;{{ pkg.package }}</b
                  >
                </div>
                <div class="col text-end" style="padding: 10px 10px 0px 10px">
                  <h2 class="text-danger">
                    <strong>{{ pkg.onBoardingCharge }}</strong>
                  </h2>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="row border-row"
                  style="
                    border-radius: 10px;
                    background-color: #f2f2f2;
                    line-height: 12px;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    padding: 10px 10px 10px 10px;
                  "
                >
                  <b style="color: #f21300"
                    ><small
                      >Earn upto Rs.15000 per month and enjoy higher comission.</small
                    ></b
                  >
                </div>

                <div class="pb-1">
                  <b class="text" style="font-size: 11pt">
                    {{ pkg.remark }}
                  </b>
                </div>
                <div class="row border-row">
                  <div class="col-md-12 col-sm-12 col-xl-12">
                    <ul class="navbar-nav mr-auto">
                      <li
                        class="nav-item"
                        style="margin-top: 2px"
                        v-for="(pkg_service, index) in pkg.package_services"
                        :key="index"
                      >
                        <div class="row">
                          <div class="col-md-1 col-xl-1">
                            <font-awesome-icon
                              icon="check"
                              class="bg-danger text-white"
                              style="padding: 2px; border-radius: 4px"
                            />
                          </div>
                          <div class="col-md-11 col-xl-11">
                            <b class="text" style="margin-left: 0px">{{
                              pkg_service.service.name
                            }}</b>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end of table area -->
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

<script>
import Banner from "../../../components/admin/comman/Banner.vue";
import Spinner from "../../../components/admin/comman/Spinner.vue";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Packages",
  retailer_id: "",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      packages: [],
      retailer_id: "",
      services: [],
      packageservice: [],
      retailerpackage: [],
      form: new Form({}),
    };
  },
  methods: {
    loadPackages() {
      this.loading = true;
      this.$axios
        .get("admin/package", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.packages = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.adminPageTitles.package
    console.log(this.$store.state.adminPageTitles.package)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadPackages();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.package-card {
  box-shadow: 0px 0px 22px 0px rgb(0 0 0 / 16%);
  margin-left: 8px;
  border-radius: 15px;
  position: relative;
  height: 100%;
  width: 96%;
}

.border-row {
  margin-left: -8px;
  margin-right: -8px;
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
